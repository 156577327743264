import FooterN from '@/app/eflayout/Footer';
import GlobalCheck from '@/app/eflayout/GlobalCheck';
import GloBarTop from '@/app/eflayout/GloBar/GloBarTop';
import GloCart from '@/app/eflayout/GloCart';
import HeaderN from '@/app/eflayout/Header';
import RegionPopup from '@/components/Popups/RegionPopup';
import ToTopIcon2 from '@/components/UI/svg/Icons/ToTopIcon2';
import { useGlobal } from '@/utils/useGlobal';
import CheckInCountdown from '@elem/ui/ef-check-in-activities/CheckInCountdown';
import { useMemoizedFn } from 'ahooks';
import PDFActivity from 'elem/compCommon/PillarPageActivities/PDFActivity';
import { configs as PDFActivityConfigs } from 'elem/compCommon/PillarPageActivities/PDFActivity/configs';
import BhStatCommon from 'lowcode/BhStat/BhStatCommon';
import dynamic from 'next/dynamic';
import { FC, useEffect, useRef, useState } from 'react';
import SignupPopupOut from '../Popups/SignupPopup/SignupPopupOut';
import TopBarCountdownJp, { TopBarCountdownLocals } from '../Popups/TopBarCountdownJp';
import css from './index.module.scss';
import { newHeaderLocales } from './newHeaderLocales';

const HeaderO: any = dynamic(() => import('@/components/headerN/header'), { ssr: false });
const FooterO: any = dynamic(() => import('@/components/footerN/footer'), { ssr: false });
const Popups = dynamic(() => import('@/components/Popups'), { ssr: false });

type PageLayout = {
    title?: string;
    children?: React.ReactNode;
    className?: string;
    style?: object;
    theme?: string;
    hideHeader?: boolean;
    hideFooter?: boolean;
    isNoFixed?: boolean;
    navChildren?: React.ReactNode;
    hideToTop?: boolean;
    gloBarObjectPage?: any;
};

const PageLayout: FC<PageLayout> = ({
    children,
    navChildren,
    theme = '',
    isNoFixed,
    hideHeader,
    hideFooter,
    hideToTop,
    gloBarObjectPage,
    className = '',
    ...ohterProps
}) => {
    const { headerList, bottomList, gloproductlist, isMobile, gtm, useRouter, gloBarObject } = useGlobal();
    const refTop = useRef<any>(null);
    const router = useRouter();
    const { locale = 'us', query, pathname } = router;
    const showNewHeader = newHeaderLocales.includes(locale);
    const [top, setTop] = useState(0);
    const [fix, setFix] = useState(0);
    //接收来自h5导航的回调,判断是否停止页面滚动
    const [isOpenMobileNav, setIsOpenMobileNav] = useState<boolean>(false);
    const [isOpenPopupFlag, setIsOpenPopupFlag] = useState<boolean>(false);
    const gloBarObject2 = gloBarObjectPage || gloBarObject;
    const headCallback = useMemoizedFn((navFlag: any) => setIsOpenMobileNav(navFlag));
    const now = query?._timer ? new Date(query?._timer).getTime() : new Date().getTime();

    // 滚动到顶部
    const toTopFn = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
            gtm.push({
                event: 'uaEvent',
                event_name: 'bottom_function',
                button_name: 'back_to_top',
            });
        }
    };

    const opencallbackPopup = (num: string) => {
        if (num === '1') {
            setIsOpenPopupFlag(true);
        } else if (num === '4') {
            setIsOpenPopupFlag(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const pageYOffset = window.pageYOffset;
            if (pageYOffset > 100) {
                refTop.current && refTop.current.classList && refTop.current.classList.add('show-top');
            } else {
                refTop.current && refTop.current.classList && refTop.current.classList.remove('show-top');
            }
        });
        setTimeout(() => {
            const header = document.querySelector('.ecoflow-frame-header') as HTMLDivElement;
            const bar = document.querySelector('.announcement-bar ') as HTMLElement;
            const bar2 = document.querySelector('.product-announcement-bar ') as HTMLElement;
            const productNaver = document.querySelector('.product_naver') as HTMLDivElement;
            let top = 0;
            let fix = 0;
            if (productNaver) {
                const computedHeaderStyle = window.getComputedStyle(productNaver);
                const positionHeaderValue = computedHeaderStyle.getPropertyValue('position');
                if (positionHeaderValue === 'sticky') {
                    fix += productNaver?.offsetHeight;
                }
            }
            if (header) {
                const computedHeaderStyle = window.getComputedStyle(header);
                const positionHeaderValue = computedHeaderStyle.getPropertyValue('position');
                if (positionHeaderValue === 'sticky') {
                    fix += header?.offsetHeight;
                }
            }
            if (bar) {
                const computedHeaderStyle = window.getComputedStyle(bar);
                const positionHeaderValue = computedHeaderStyle.getPropertyValue('position');
                if (positionHeaderValue === 'sticky') {
                    top += bar?.offsetHeight;
                    fix += bar?.offsetHeight;
                }
            }
            if (bar2) {
                const computedHeaderStyle = window.getComputedStyle(bar2);
                const positionHeaderValue = computedHeaderStyle.getPropertyValue('position');
                if (positionHeaderValue === 'sticky') {
                    top += bar2?.offsetHeight;
                    fix += bar2?.offsetHeight;
                }
            }
            setTop(top);
            setFix(fix);
        }, 1500);
    }, []);

    return (
        <div
            className={`${css['pagelayout-bd']} page-layout ${isOpenMobileNav ? 'ecoflow-frame-open-mobile-nav' : ''} ${
                isOpenPopupFlag ? 'ecoflow-frame-openpopup' : ''
            } ${isNoFixed ? 'ecoflow-isNoFixed' : ''} ${className} ${theme}`}
            {...ohterProps}
        >
            {/* <TopBars />
            <Floats /> */}
            {!hideHeader && (
                <div className="ecoflow-frame-header" style={{ top: top, background: '#fff' }}>
                    <BhStatCommon />
                    <RegionPopup callback={opencallbackPopup} />
                    {TopBarCountdownLocals.includes(locale) && <TopBarCountdownJp />}
                    <GloBarTop gloBarObject={gloBarObject2.__globar_top} />
                    {showNewHeader && <HeaderN headerList={headerList} gloproductlist={gloproductlist} />}
                    {!showNewHeader && <HeaderO isMobile={isMobile} callback={headCallback} />}
                </div>
            )}
            {navChildren}
            {PDFActivityConfigs?.times[locale]?.start &&
                PDFActivityConfigs?.times[locale]?.end &&
                now >= new Date(PDFActivityConfigs?.times[locale]?.start).getTime() &&
                now < new Date(PDFActivityConfigs?.times[locale]?.end).getTime() &&
                PDFActivityConfigs?.includeSites.includes(locale) &&
                PDFActivityConfigs?.includePages?.includes(pathname) && <PDFActivity top={fix} />}
            <Popups />
            <section className="ecoflow-frame-main allContentContainer" id="allContentContainer" role="main">
                {children}
            </section>
            {!hideFooter && !showNewHeader && <FooterO theme={theme} />}
            {!hideFooter && showNewHeader && <FooterN bottomList={bottomList} theme={theme} />}
            {!hideToTop && (
                <div className="ecoflow-frame-totop ant-back-top" ref={refTop} onClick={toTopFn}>
                    <ToTopIcon2 />
                </div>
            )}
            <GloCart />
            <SignupPopupOut />
            <GlobalCheck />
            <CheckInCountdown />
        </div>
    );
};

export default PageLayout;
